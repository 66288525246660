import { useState } from 'react';

import { CloudDownload as CloudDownloadIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
	Box,
	Button,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	Stack,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DatePickerNew } from '~components';
import { ItemCategoryEnum, PeriodicityEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { Hub, HubAutocomplete } from '~features/hub';
import { Item, ItemAutocomplete, ItemCategory, ItemCategorySelect } from '~features/items';
import { PartnerAutocomplete } from '~features/organisations';

interface StatisticsFilters {
	item: Item | null;
	hub: Hub | null;
	category: ItemCategory | null;
}

interface StatisticsToolbarProps {
	organisationId?: string;
	date: Dayjs | null;
	onDateChange?: (value: Dayjs | null) => void;
	periodicity: PeriodicityEnum | null;
	onPeriodicityChange?: (value: PeriodicityEnum | null) => void;
	filters: StatisticsFilters;
	onFiltersChange: (value: StatisticsFilters) => void;
	disableFilters?: boolean;
}

const StatisticsToolbar = ({
	filters = {},
	onFiltersChange,
	date,
	onDateChange,
	periodicity,
	onPeriodicityChange,
	disableFilters = false,
	...props
}: StatisticsToolbarProps) => {
	const { isSuperAdmin } = useAuthorize();
	const { t } = useTranslation('general');
	const [anchorElMore, setAnchorElMore] = useState<null | HTMLElement>(null);

	return (
		<Stack spacing={1} sx={{ width: 1 }}>
			<Stack direction='row-reverse' spacing={1} sx={{ justifyContent: 'space-between' }}>
				<Stack spacing={1} direction='row' sx={{ alignItems: 'center' }}>
					<ToggleButtonGroup
						size='small'
						exclusive
						value={periodicity}
						onChange={(_, value) => value != null && onPeriodicityChange?.(value)}
					>
						{Object.values(PeriodicityEnum).map((el: PeriodicityEnum) => (
							<ToggleButton key={el} value={el}>
								{el}
							</ToggleButton>
						))}
					</ToggleButtonGroup>
					<DatePickerNew
						periodicity={periodicity ?? null}
						value={date}
						onChange={(value) => value != null && onDateChange?.(value)}
					/>
					<IconButton
						onClick={(e) => setAnchorElMore(e.currentTarget)}
						sx={{ height: 'fit-content' }}
					>
						<MoreVertIcon />
					</IconButton>
					<Menu
						anchorEl={anchorElMore}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorElMore)}
						onClose={() => setAnchorElMore(null)}
						sx={{ mt: '45px' }}
					>
						<MenuItem>
							<ListItemIcon>
								<CloudDownloadIcon />
							</ListItemIcon>
							<ListItemIcon>{t('export')}</ListItemIcon>
						</MenuItem>
					</Menu>
				</Stack>

				{!disableFilters && (
					<Stack direction='row' spacing={1}>
						<ItemAutocomplete
							value={filters.item ?? null}
							onChange={(_, value) => onFiltersChange?.({ ...filters, item: value })}
							organisationId={props.organisationId}
							sx={{ width: 200 }}
						/>
						<HubAutocomplete
							value={filters.hub ?? null}
							onChange={(_, value) => onFiltersChange?.({ ...filters, hub: value })}
							organisationId={props.organisationId}
							sx={{ width: 200 }}
						/>
						<ItemCategorySelect
							displayEmpty
							value={filters.category}
							onChange={(_, value) => onFiltersChange({ ...filters, category: value })}
							sx={{ width: 200 }}
						/>
						{!isSuperAdmin() && <PartnerAutocomplete />}
					</Stack>
				)}
			</Stack>
		</Stack>
	);
};

export default StatisticsToolbar;
