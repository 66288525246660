import { DateRange } from '~interfaces/dateRanges';
import { TopologyService } from '~services';

interface TripStat {
	distanceWithBooking: number;
	distanceWithoutBooking: number;
}

class TripStatisticsService extends TopologyService {
	public readonly path = 'graphs/distances';

	constructor() {
		super('v1');
	}

	async getCounts({
		...args
	}: {
		organisationId?: string;
		period: DateRange;
	}): Promise<TripStat> {
		const { data } = await this._client.get<TripStatResponse>(
			this.path, {
				params: {
					organisationId: args.organisationId,
					dateAfter: args.period.start,
					dateBefore: args.period.end,
				}
			}
		);

		return {
			distanceWithBooking: data.withBooking,
			distanceWithoutBooking: data.withoutBooking,
		};
	}
}

interface TripStatResponse {
	withBooking: number;
	withoutBooking: number;
}

export default TripStatisticsService;
