import {
	CircularProgress,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
	SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BaseReference } from '~interfaces';
import i18n from '~lib/i18n';

type AsyncSelectProps<T extends BaseReference> = Omit<MuiSelectProps, 'children'> & {
	value: T | undefined;
	loading?: boolean;
	onChange?: (e: SelectChangeEvent<string>, value: T | undefined) => void;
	options: T[];
	clearable?: boolean;
	allLabel?: string;
};

/**
 * Finish
 * @param props
 * @returns
 */
const AsyncSelect = <T extends BaseReference>({
	loading = false,
	value,
	onChange,
	options,
	clearable = false,
	allLabel = i18n.t('all'),
	...props
}: AsyncSelectProps<T>) => {
	const { t } = useTranslation('general');

	const label = props.required && props.label ? `${props.label} *` : props.label;

	const handleChange = (event: SelectChangeEvent<string>) => {
		if (event.target.value === '') {
			onChange?.(event, null);
		} else {
			const option = options.find((el) => el.id === event.target.value);
			onChange?.(event, option);
		}
	};

	return (
		<FormControl fullWidth>
			<InputLabel id={props.labelId}>{label}</InputLabel>
			<MuiSelect
				{...props}
				label={label}
				value={value?.id}
				onChange={handleChange}
				endAdornment={
					<InputAdornment
						position='end'
						sx={{ position: 'absolute', right: 35, pointerEvents: 'none' }}
					>
						{loading && <CircularProgress size={20} color='inherit' />}
						{/* <IconButton size='small'>
							<Close fontSize='small' />
						</IconButton> */}
					</InputAdornment>
				}
			>
				{loading && <MenuItem disabled>{`${t('loading')}...`}</MenuItem>}
				{!loading && clearable && (
					<MenuItem value=''>
						<em>{allLabel}</em>
					</MenuItem>
				)}
				{!loading &&
					options.map((el) => (
						<MenuItem key={el.id} value={el.id}>
							{el.label}
						</MenuItem>
					))}
			</MuiSelect>
		</FormControl>
	);
};

export default AsyncSelect;
