import { Skeleton } from '@mui/material';
import { legendClasses, PieChart } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { PieChartCenterLabel } from '~components';

import DeviceStatisticsService from '../../services/deviceStatisticsService';
import { colorsFull, colorsSlim } from '~themes/chartColors';

const service = new DeviceStatisticsService();

interface DeviceCountPieChartProps {
	organisationId?: string;
}

const DeviceCountPieChart = ({ organisationId }: DeviceCountPieChartProps) => {
	const { t } = useTranslation('general');

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId }],
		([_, args]) => service.getCounts(args),
	);

	if (!data) {
		return 'todo';
	}

	data.results.sort((a, b) => b.total > a.total ? 1 : -1);

	return (
		<PieChart
			colors={data.results.length > 3 ? colorsFull : colorsSlim}
			loading={isLoading}
			series={
				data != null ?
					[
						{
							data: data.results.map((el) => ({ label: t(el.type === 'unlinked' ? 'unlinked' : `ui.label.${el.type}`), value: el.total })),
							outerRadius: '100%',
							innerRadius: '80%',
						},
					]
				:	[]
			}
			slotProps={{
				legend: {
					itemMarkWidth: 14,
					itemMarkHeight: 14,
				}
			}}
			sx={{
				[`& .${legendClasses.mark}`]: {
					ry: 10,
				},
			}}
		>
			<PieChartCenterLabel primary='Total devices' secondary={data?.total} />
		</PieChart>
	);
};

export default DeviceCountPieChart;
