import path from 'path';
import { ItemCategoryEnum } from '~enums';
import { Hub } from '~features/hub';
import { BaseReference, ListResults, PagedResults } from '~interfaces';
import { DateRange } from '~interfaces/dateRanges';
import { BasePagedParameters } from '~interfaces/requests';
import { AddressResponse, BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';
import AddressModel from '../../../models/addressModel';

interface FinanceStat {
	timestamp: Date;
	revenue: number; // in euros?
}

interface ItemFinanceStat extends BaseReference {
	revenue: number;
	itemGroup: BaseReference;
	hub: Hub;
	bookingCount: number;
}

class FinanceStatisticsService extends TopologyService {
	public readonly path = 'graphs/revenues';

	constructor() {
		super('v1');
	}

	async getRevenues({
		...args
	}: {
		organisationId?: string;
		period: DateRange;
		itemId?: string;
		hubId?: string;
	}): Promise<ListResults<FinanceStat>> {
		const { data } = await this._client.get<FinanceStatResponse>(this.path, {
			params: {
				organisationId: args.organisationId,
				dateAfter: args.period.start,
				dateBefore: args.period.end,
				step: 'week'
			}
		});

		return {
			total: data.revenues,
			results: data.billings.map(el => ({
				timestamp: new Date(el.dateTime),
				revenue: el.revenues
			}))
		};
	}

	async getItemRevenues({
		page = 1,
		pageSize = 5,
		...args
	}: {
		period: DateRange
	} & BasePagedParameters): Promise<PagedResults<ItemFinanceStat>> {
		const { data } = await this._client.get<PagedResponse<ItemFinanceStatResponse>>(
			path.join(this.path, 'list'), {
				params: {
					organisationId: args.organisationId,
					pageNumber: page,
					pageSize: pageSize,
					dateAfter: args.period.start,
					dateBefore: args.period.end,
				}
			}
		);

		return this.mapPagedResponse(data, FinanceStatisticsService.fromResponse);
	}

	static fromResponse(data: ItemFinanceStatResponse): ItemFinanceStat {
		const { id, name, item, location, dateTime, bookings, revenues, ...rest } = data;

		return {
			...FinanceStatisticsService.fromBaseReferenceResponse({
				id: id,
				name: name
			}),
			bookingCount: bookings,
			revenue: revenues,
			itemGroup: FinanceStatisticsService.fromBaseReferenceResponse(item),
			hub: {
				id: location.id.toString(),
				label: location.name,
				address: new AddressModel(
					location.address.country,
					location.address.city,
					location.address.postalCode,
					location.address.street,
					Number(location.address.number),
				)
			},
			...rest
		};
	}
}

interface ItemFinanceStatResponse extends BaseReferenceResponse {
	bookings: number;
	revenues: number;
	item: BaseReferenceResponse;
	location: {
		address: AddressResponse;
	} & BaseReferenceResponse;
}

interface FinanceStatResponse {
	billings: ({
		dateTime: string;
		revenues: number;
	})[];
	revenues: number;
}

export default FinanceStatisticsService;
