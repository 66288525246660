import { useState } from 'react';

import { AxiosResponse } from 'axios';

interface useDownloadFileProps {
	fetcher: () => AxiosResponse;
}

const useDownloadFile = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState();

	const downloadFile = async (fetcher: () => Promise<AxiosResponse>, fileName: string) => {
		setLoading(true);

		try {
			const response = await fetcher();
			const url = URL.createObjectURL(response);
			const link = document.createElement('a');
			link.href = url;
			link.download = fileName;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	return {
		isLoading: loading,
		error: error,
		progress: 0,
		downloadFile,
	};
};

export default useDownloadFile;
