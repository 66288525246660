import { useMemo, useRef } from 'react';

import { axisClasses, BarChart, LineChart, PieChart } from '@mui/x-charts';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { PeriodicityEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';
import { colorsSlim } from '~themes/chartColors';

import FinanceStatisticsService from '../../services/financeStatisticsService';

const dateLongMonthAndYearFormatter = new Intl.DateTimeFormat('nl', {
	month: 'long',
	year: 'numeric',
});
const dateShortMonthAndYearFormatter = new Intl.DateTimeFormat('nl', {
	month: 'short',
	year: 'numeric',
});

const service = new FinanceStatisticsService();

interface RevenuesLineChartProps {
	organisationId?: string;
	period: DateRange;
	itemId: string;
	hubId: string;
	periodicity?: PeriodicityEnum;
}

dayjs.extend(weekOfYear);

const now = dayjs();

const RevenuesLineChart = ({
	period = {
		start: now.subtract(3, 'month').toDate(),
		end: now.toDate(),
	},
	...props
}: RevenuesLineChartProps) => {
	const { t } = useTranslation('general');

	const fetchParameters = useMemo(
		() => ({
			period: period,
			...props
		}),
		[props, period],
	);

	const { data, isLoading } = useSWR(
		[service.basePath, fetchParameters],
		([_, args]) => service.getRevenues(args),
	);

	return (
		<LineChart
			loading={isLoading}
			colors={colorsSlim}
			xAxis={
				data != null ?
					[
						{
							scaleType: 'time',
							data: data.results.map((el) => el.timestamp),
							valueFormatter: (value: Date, context) => {
								if (context.location !== 'tick' && props.periodicity === PeriodicityEnum.Monthly) {
									return `${t('week')} ${dayjs(value).week().toString()}`;
								} else if (context.location !== 'tick') {
									return dateLongMonthAndYearFormatter.format(value);
								}

								return dateShortMonthAndYearFormatter.format(value);
							},
							tickNumber: 3,
						},
					]
				:	undefined
			}
			yAxis={[{ valueFormatter: (value: number) => `€${value.toLocaleString()}` }]}
			series={
				data != null ?
					[
						{
							data: data.results.map((el) => el.revenue),
							label: t('nav.financialReports.revenue'),
							showMark: false,
							valueFormatter: (value: number | null) =>
								value != null ? `€${value.toLocaleString()}` : 'unknown',
						},
					]
				:	[]
			}
			margin={{ left: 80 }}
			grid={{ horizontal: true }}
		/>
	);
};

export default RevenuesLineChart;
