import {
	NavigateBefore as NavigateBeforeIcon,
	NavigateNext as NavigateNextIcon,
} from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import {
	DatePicker as MuiDatePicker,
	DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { PeriodicityEnum } from '~enums';

interface DatePickerProps extends MuiDatePickerProps<Dayjs> {
	periodicity?: PeriodicityEnum;
	disableNavigateButtons?: boolean;
}

const DatePicker = ({
	periodicity = PeriodicityEnum.Monthly,
	disableNavigateButtons = false,
	value,
	disableOpenPicker = true,
	maxDate = dayjs(),
	readOnly = true,
	...muiDatePickerProps
}: DatePickerProps) => {
	const { t } = useTranslation('general');

	const getFormat = (periodicity: PeriodicityEnum | 'daily') => {
		switch (periodicity) {
			case PeriodicityEnum.Yearly:
				return 'YYYY';
			case PeriodicityEnum.Monthly:
				return 'MMMM YYYY';
			default:
				return 'DD MMMM YYYY';
		}
	};

	const mapPeriodicity = (periodicity: PeriodicityEnum | 'daily'): dayjs.ManipulateType => {
		switch (periodicity) {
			case PeriodicityEnum.Yearly:
				return 'year';
			case PeriodicityEnum.Monthly:
				return 'month';
			default:
				return 'day';
		}
	};

	return (
		<Stack direction='row' spacing={0.5} sx={{ alignItems: 'center' }}>
			{!disableNavigateButtons && (
				<IconButton
					onClick={() =>
						muiDatePickerProps.onChange?.(value?.subtract(1, mapPeriodicity(periodicity)))
					}
					sx={{ height: 'fit-content' }}
				>
					<NavigateBeforeIcon />
				</IconButton>
			)}
			<MuiDatePicker
				{...muiDatePickerProps}
				value={value}
				format={getFormat(periodicity)}
				readOnly={readOnly}
				maxDate={maxDate}
				disableOpenPicker={disableOpenPicker}
				slotProps={{
					textField: {
						variant: 'standard',
					},
				}}
			/>
			{!disableNavigateButtons && (
				<IconButton
					disabled={value != null && value?.add(1, mapPeriodicity(periodicity)) > maxDate}
					onClick={() => muiDatePickerProps.onChange?.(value?.add(1, mapPeriodicity(periodicity)))}
					sx={{ height: 'fit-content' }}
				>
					<NavigateNextIcon />
				</IconButton>
			)}
		</Stack>
	);
};

export default DatePicker;
