import { ItemCategoryEnum } from '~enums';
import { BaseReference } from '~interfaces';
import { BaseReferenceResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import ItemCategory from '../interfaces/itemCategory';

class ItemCategoryService extends TopologyService {
	public readonly path = 'categories';

	constructor() {
		super('v1');
	}

	async getCategories(): Promise<ItemCategory[]> {
		const { data } = await this._client.get<ItemCategoryResponse[]>(this.path);

		return data.map((el) => ({
			...ItemCategoryService.fromBaseReferenceResponse(el),
			type: el.type as ItemCategoryEnum,
		}));
	}
}

interface ItemCategoryResponse extends BaseReferenceResponse {
	type: string;
	order: number;
	icon: string;
}

export default ItemCategoryService;
