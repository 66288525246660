enum Includes {
	None = 0,
	Country = 1 << 0,
	City = 1 << 1,
	PostalCode = 1 << 2,
	Street = 1 << 3,
	Number = 1 << 4,
	NumberAddition = 1 << 5,
}

class AddressModel {
	public country: string;
	public city: string;
	public postalCode: string;
	public street: string;
	public number: number;
	public addition?: string;

	constructor(
		country: string,
		city: string,
		postalCode: string,
		street: string,
		number: number,
		addition?: string,
	) {
		this.country = country;
		this.city = city;
		this.postalCode = postalCode;
		this.street = street;
		this.number = number;
		this.addition = addition;
	}

	toPostalCodeAndUpString(): string {
		return `${this.postalCode}, ${this.city} (${this.country})`;
	}

	// WIP
	toString(includes: Includes = Includes.City | Includes.Street): string {
		let result = '';

		if ((includes & Includes.Street) === Includes.Street) {
			result += ` ${this.street}`;
		} else if ((includes & Includes.Number) === Includes.Number) {
			result += ` ${this.number}${this.addition && (includes & Includes.NumberAddition) === Includes.NumberAddition ? ` ${this.addition}` : ''}`;
		}

		return result;
	}

	toCityStreetString(includeHouseNumber = true): string {
		if (!includeHouseNumber) {
			return `${this.street}, ${this.city}`;
		}

		return `${this.toStreetString()}, ${this.city}`;
	}

	toStreetString(): string {
		return `${this.street}${this.number != null ? ` ${this.number}${this.addition ?? ''}` : ''}`;
	}
}

export default AddressModel;
