import { useMemo, useRef } from 'react';

import { BarChart } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { DateRange } from '~interfaces/dateRanges';
import { colorsSlim } from '~themes/chartColors';

import BillingPeriodStatisticsService from '../../services/billingPeriodStatisticsService';

const service = new BillingPeriodStatisticsService();

interface BillingPeriodCountBarChartProps {
	organisationId?: string;
	period: DateRange;
}

const BillingPeriodCountBarChart = ({ organisationId, period }: BillingPeriodCountBarChartProps) => {
	const { t } = useTranslation('general');

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId, period: period }],
		([_, args]) => service.getBillingPeriodCounts(args),
	);

	return (
		<BarChart
			loading={isLoading}
			colors={colorsSlim}
			xAxis={data == null ? [] : [
				{
					scaleType: 'band',
					data: Object.keys(data).map(el => t(`ui.status.${el}`)),
				},
			]}
			series={data == null ? [] : [
				{ data: [data.open, data.closed, data.revoked] }
			]}
		/>
	);
};

export default BillingPeriodCountBarChart;
