import dayjs from 'dayjs';
import { ItemCategoryEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';
import { TopologyService } from '~services';

interface UserLicenseStat {
	timestamp: Date;
	approved: number;
	requests: number;
	rejected: number;
	uniqueUsers: number;
}

class UserLicenseStatisticsService extends TopologyService {
	public readonly path = 'graphs/licenses';

	constructor() {
		super('v1');
	}

	async getCounts({
		...args
	}: {
		organisationId?: string,
		period: DateRange
	}): Promise<UserLicenseStat[]> {
		const { data } = await this._client.get<UserLicenseStatResponse[]>(this.path, {
			params: {
				organisationId: args.organisationId,
				dateAfter: args.period.start.toISOString(),
				dateBefore: args.period.end.toISOString(),
				step: 'month'
			}
		});

		return data.map(el => {
			const { dateTime, uniqueUsers, total, ...rest } = el;

			return {
				...rest,
				requests: total,
				timestamp: new Date(dateTime),
				uniqueUsers: uniqueUsers
			};
		});
	}
}

interface UserLicenseStatResponse {
	dateTime: string;
	rejected: number;
	approved: number;
	uniqueUsers: number;
	total: number;
}

export default UserLicenseStatisticsService;
