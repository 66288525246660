import path from 'path';

import WeekdayEnum from '~enums/dayOfWeekEnum';
import {
	IdReference,
	PagedResults
} from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import {
	BaseReferenceResponse,
	SkcPagedResponse,
	SkcSingleResponse,
} from '~interfaces/responses';
import { TopologyProxiedSkopeiConnectService } from '~services';
import { parseTime, time24Format } from '~utils/dateUtils';

import SkcSchedule from '../interfaces/skcSchedule';

/**
 * A service that does calls to the Skopei Connect API through
 * the Topology backend
 */
class SkcSchedulesService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'schedules';

	async getSchedules({
		page = 1,
		pageSize = 10,
		...args
	}: SearchPagedParameters): Promise<PagedResults<SkcSchedule>> {
		const { data } = await this._client.get<SkcPagedResponse<ScheduleResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
				organisationId: args.organisationId,
				textQuery: args.searchQuery || undefined,
			},
		});

		return this.mapPagedResponse(data, SkcSchedulesService.fromResponse);
	}

	async getScheduleById(id: string): Promise<SkcSchedule> {
		const { data } = await this._client.get<SkcSingleResponse<ScheduleResponse>>(
			path.join(this.path, id),
		);

		return SkcSchedulesService.fromResponse(data.data);
	}

	async updateSchedule(id: string, data: SkcSchedule): Promise<null> {
		const content = SkcSchedulesService.toRequest(data);
		await this._client.put(
			path.join(this.path, id),
			content,
		);

		return null;
	}

	async createSchedule(data: SkcSchedule): Promise<IdReference> {
		const content = SkcSchedulesService.toRequest(data);

		const response = await this._client.post(this.path, content, {
			params: {
				organisationId: data.organisation.id,
			},
		});

		return this.mapIdResponse(response.data);
	}

	async deleteSchedule(id: string): Promise<null> {
		await this._client.delete(path.join(this.path, id));

		return null;
	}
	
	static toRequest(data: SkcSchedule): ScheduleRequest {
		const { label, description, schedule } = data;

		return {
			name: label,
			allDay: schedule.isOpenAllDay,
			openPeriodStart: time24Format.format(schedule.period?.start),
			openPeriodEnd: time24Format.format(schedule.period?.end),
			weekDays: schedule.daysOfWeek,
			description: description || undefined,
		};
	}

	static fromResponse(data: ScheduleResponse): SkcSchedule {
		const { id, name, allDay, description, weekDays, openPeriodEnd, openPeriodStart } = data;

		return {
			id: id.toString(),
			label: name,
			description: description,
			schedule: {
				isOpenAllDay: allDay,
				...(openPeriodStart != null &&
					openPeriodEnd != null && {
					period: {
						start: parseTime(openPeriodStart),
						end: parseTime(openPeriodEnd),
					},
				}),
				daysOfWeek: weekDays as WeekdayEnum[],
			},
		};
	}
}

interface ScheduleRequest {
	name: string;
	description?: string;
	allDay: boolean;
	openPeriodStart?: string; // xx:xx:xx
	openPeriodEnd?: string; // xx:xx:xx
	weekDays: ('sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday')[];
}

interface ScheduleResponse extends ScheduleRequest, BaseReferenceResponse { }

export default SkcSchedulesService;
