import {
	TrendingDown as TrendingDownIcon,
	TrendingFlat as TrendingFlatIcon,
	TrendingUp as TrendingUpIcon,
} from '@mui/icons-material';
import { Skeleton, Stack, Typography } from '@mui/material';
import { SparkLineChart } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { DateRange } from '~interfaces/dateRanges';
import { colorsSlim } from '~themes/chartColors';

import UserLicenseStatisticsService from '../../services/userLicenseStatisticsService';

const service = new UserLicenseStatisticsService();

interface UserLicenseCountChart {
	type: 'requests' | 'rejected' | 'approved' | 'uniqueUsers';
	organisationId?: string;
	period: DateRange;
}

const UserLicenseCountChart = ({ organisationId, period, type }: UserLicenseCountChart) => {
	const { t } = useTranslation('general');

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId, period: period }],
		([_, args]) => service.getCounts(args),
	);

	if (isLoading) {
		return <Skeleton variant='circular' width={120} height={120} />;
	} else if (!data) {
		return 'no data';
	}

	const mappedData = data?.map(el => ({
		timestamp: el.timestamp,
		type: type,
		value: el[type]
	})).reverse();
	
	const difference = mappedData.length > 1 ? mappedData[0].value - mappedData[1].value : 0;

	return (
		<Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
			<Typography variant='h4'>{mappedData[0]?.value ?? 0}</Typography>
			{difference === 0 ?
				<TrendingFlatIcon />
			: difference > 0 ?
				<TrendingUpIcon color='success' />
			:	<TrendingDownIcon color='error' />}
			<Typography
				color={difference > 0 ? 'success' : difference < 0 ? 'error' : undefined}
			>
				{`${difference > 0 ? '+' : ''}${difference}`}
			</Typography>
			<SparkLineChart
				data={mappedData.map(el => el.value)}
				height={100}
				colors={colorsSlim}
				area
				curve='natural'
			/>
		</Stack>
	);
};

export default UserLicenseCountChart;
