import React, { useEffect, useRef, useState } from 'react';

import { Add as AddIcon, MoreVert, NavigateBefore } from '@mui/icons-material';
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	IconButton,
	OutlinedInput,
	Stack,
	TextField,
} from '@mui/material';

import { DatePickerNew, FormDialog, Select } from '~components';
import DefectSelect from '~features/maintenance/components/selects/defectSelect';
import { OrganisationAutocomplete, PartnerAutocomplete } from '~features/organisations';
import { BaseReferenceResponse } from '~interfaces/responses';
import { ItemGroup } from '~features/itemGroups';
import { Item, ItemCategorySelect } from '~features/items';
import { Hub } from '~features/hub';
import { User } from '~features/users';
import { useElementSize, useOverflow } from '~hooks';
import UserCountPieChart from '~features/users/components/charts/userCountPieChart';
import ItemCountBarChart from '~features/items/components/charts/itemCountBarChart';
import DeviceCountPieChart from '~features/devices/components/charts/deviceCountPieChart';
import BookingsLineChart from '~features/bookings/components/charts/bookingsLineChart';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import readWriteOrganisationAtom from '~atoms/organisationAtom';
import HubAutocomplete from '~features/hub/components/autocompletes/hubAutocomplete';
import { atomWithHash } from 'jotai-location';

const countAtom = atomWithHash('count', 1)

/**
 * This page is meant as a sandbox for a develop to develop or
 * test a component outside of the normal flow
 */
const SandboxPage = () => {
	const [org, setOrg] = useAtom(readWriteOrganisationAtom);
	const [dateVal, setDateVal] = useState<Dayjs | null>(dayjs());
	const [count, setCount] = useAtom(countAtom);
	const [val, setVal] = useState(null);

	const myRef = useRef();
	const size = useElementSize(myRef);
	const overflow = useOverflow(myRef);

	return (
		<Box width={1} height={1}>
			<HubAutocomplete />
			<Button onClick={() => setCount(prev => prev + 1)}>+</Button>
			{count}
			<ItemCategorySelect
				value={val}
				onChange={(e, newVal) => setVal(newVal)}
			/>
			{val?.label}
			<OrganisationAutocomplete value={org} onChange={(_, newVal) => setOrg(newVal)} />
			<PartnerAutocomplete />
			{org?.label}
			<DatePickerNew
				label='Month'
				views={['month', 'year']}
				value={dateVal}
				onChange={(newDateVal) => setDateVal(newDateVal)}
			/>

			<Autocomplete
				options={[
					{ label: 'hoi' },
					{ label: 'piet' },
				]}
				renderInput={(params) => (
					<TextField {...params} select />
				)}
			/>
		</Box>
	);

	return (
		<Box width={1} height={1}>
			<Box width={300}>
				<DefectSelect value={val} onChange={(e) => setVal(e.target.value)} itemGroupId={'1849'} />
			</Box>
			<OrganisationAutocomplete />
			<Box>
				<CircularProgress size={20} color='inherit' />
			</Box>
			<Stack ref={myRef} direction='row' sx={{ width: 1, justifyContent: 'space-between' }}>
				<Box sx={{ bgcolor: 'lightblue' }}>
					asdfasdjfljasdkfjksldjfalksdfkjdkjfkdjsfksjflkjsdkfjslkdjfkldsjfjklsdf
				</Box>
				{!overflow ?
					<Stack direction='row' sx={{ bgcolor: 'orange' }}>
						<Button>Button2</Button>
						<Button>Button3</Button>
						<Button>Button4</Button>
					</Stack>
				:	<IconButton>
						<MoreVert />
					</IconButton>
				}
			</Stack>
			{`${size.width} ${size.height}`}
		</Box>
	);
};

type Bla<B extends boolean> = B extends true ? string : number;

const func = <B extends boolean>(x: B): Bla<B> => {
	throw 'unimplemented';
};

const bie = true;
type boe = Bla<typeof bie>;

type Booking = {
	user: User;
	item: Item;
	itemGroup: ItemGroup;
	hub: Hub;
	usage: {
		start: Date;
		end?: Date;
	};

	price: object;

	status: string;
	usageState: string;
	approvalState: string;

	created: Date;
	createdByUser: BaseReferenceResponse;

	start: Date;
	end: Date;

	attentionStates: string[];
} & BaseReferenceResponse;

export default SandboxPage;
