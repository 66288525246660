import { useState } from 'react';

import { Box, Card, CardContent, CardHeader, Grid2, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { organisationAtom } from '~atoms';
import { PeriodicityEnum } from '~enums';
import { UserLicenseCountChart } from '~features/users';
import { DetailsGridLayout } from '~layouts';

import StatisticsToolbar from '../components/toolbars/statisticsToolbar';

const UserLicenseStatisticsLayout = () => {
	const { t } = useTranslation('general');
	const organisation = useAtomValue(organisationAtom);
	const [date, setDate] = useState(dayjs());
	const [periodicity, setPeriodicity] = useState(PeriodicityEnum.Monthly);

	const getPeriod = (date: Dayjs, periodicity: PeriodicityEnum, monthRange = 1): DateRange => {
		const end = date >= dayjs().startOf('month') ? dayjs() : date.endOf('month');

		return {
			start: end.subtract(periodicity === PeriodicityEnum.Monthly ? monthRange : 11, 'months').toDate(),
			end: end.toDate()
		};
	};

	return (
		<Stack spacing={2} sx={{ height: 1 }}>
			<StatisticsToolbar
				disableFilters
				date={date}
				onDateChange={(value) => setDate(value)}
				periodicity={periodicity}
				onPeriodicityChange={(value) => setPeriodicity(value)}
			/>
			<DetailsGridLayout>
				{['requests', 'rejected', 'approved', 'uniqueUsers'].map(el =>
					<Card>
						<CardHeader title={t(el)} />
						<CardContent>
							<UserLicenseCountChart
								key={el}
								type={el}
								organisationId={organisation?.id}
								period={{
									start: date.subtract(6, 'month').startOf('month').toDate(),
									end: date.endOf('month').toDate(),
								}}
							/>
						</CardContent>
					</Card>
				)}
			</DetailsGridLayout>
		</Stack>
	);
};

export default UserLicenseStatisticsLayout;
