import { useMemo, useRef } from 'react';

import { BarChart, legendClasses, PieChart } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { colorsSlim } from '~themes/chartColors';

import ItemStatisticsService from '../../services/itemStatisticsService';

const service = new ItemStatisticsService();

interface ItemCountBarChartProps {
	organisationId?: string;
}

const ItemCountBarChart = ({ organisationId }: ItemCountBarChartProps) => {
	const { t } = useTranslation('general');

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId }],
		([_, args]) => service.getCounts(args),
	);

	return (
		<BarChart
			loading={isLoading}
			colors={colorsSlim}
			xAxis={
				data != null ?
					[
						{
							scaleType: 'band',
							data: data.map((el) => t(`ui.label.${el.category}`)),
						},
					]
				:	undefined
			}
			series={
				data != null ?
					[
						{ data: data.map((el) => el.activeCount), label: t('active') },
						{ data: data.map((el) => el.inactiveCount), label: t('inactive') }
					]
				:	[]
			}
			grid={{ horizontal: true }}
			slotProps={{
				legend: {
					itemMarkWidth: 14,
					itemMarkHeight: 14,
				}
			}}
			sx={{
				[`& .${legendClasses.mark}`]: {
					ry: 10,
				},
			}}
		/>
	);
};

export default ItemCountBarChart;
