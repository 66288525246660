import { useMemo, useRef } from 'react';

import { BarChart } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { DateRange } from '~interfaces/dateRanges';

import TripStatisticsService from '../../services/tripStatisticsService';
import { colorsSlim } from '~themes/chartColors';

const service = new TripStatisticsService();

interface ItemCountBarChartProps {
	organisationId?: string;
	period: DateRange;
}

const TripDistancesBarChart = ({ organisationId, period }: ItemCountBarChartProps) => {
	const { t } = useTranslation('general');

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId, period: period }],
		([_, args]) => service.getCounts(args),
	);

	console.log(data)

	return (
		<BarChart
			loading={isLoading}
			colors={colorsSlim}
			xAxis={
				data != null ?
					[
						{
							scaleType: 'band',
							data: Object.keys(data).map(el => t(el)),
						},
					]
				:	undefined
			}
			yAxis={[{ valueFormatter: (value: number) => `${value.toLocaleString()}km` }]}
			series={
				data != null ?
					[
						{
							data: [data.distanceWithBooking, data.distanceWithoutBooking],
							valueFormatter: (value: number | null) =>
								value != null ? `${value.toLocaleString()}km` : 'unknown',
						},
					]
				:	[]
			}
			margin={{ left: 80 }}
			grid={{ horizontal: true }}
		/>
	);
};

export default TripDistancesBarChart;
