import { Skeleton } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { PieChartCenterLabel } from '~components';
import { colorsSlim } from '~themes/chartColors';

import UserStatisticsService from '../../services/userStatisticsService';

const service = new UserStatisticsService();

interface UserCountPieChart {
	organisationId?: string;
}

const UserCountPieChart = ({ organisationId }: UserCountPieChart) => {
	const { t } = useTranslation('general');

	const { data, isLoading } = useSWR(
		[service.basePath, { organisationId: organisationId }],
		([_, args]) => service.getCounts(args),
	);

	if (!data) {
		return 'todo';
	}

	const { total, ...rest } = data;

	return (
		<PieChart
			loading={isLoading}
			colors={colorsSlim}
			series={[
				{
					data: Object.keys(rest).map((el: string) => ({
						label: () => {
							if (el === 'new') {
								return `${t('new')} (30d)`;
							} else if (el === 'nfc') {
								return el.toUpperCase();
							}

							return t(el);
						},
						value: rest[el],
					})),
					outerRadius: '100%',
					innerRadius: '80%',
				},
			]}
		>
			<PieChartCenterLabel primary='Total users' secondary={data?.total} />
		</PieChart>
	);
};

export default UserCountPieChart;
