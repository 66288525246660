import { Card, CardContent, CardHeader } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { organisationAtom } from '~atoms';
import { DeviceCountPieChart } from '~features/devices';
import { ItemCountBarChart } from '~features/items';
import { UserCountPieChart } from '~features/users';
import { DetailsGridLayout } from '~layouts';

const rowHeight = '250px';

const OverviewStatisticsLayout = () => {
	const { t } = useTranslation('general');
	const organisation = useAtomValue(organisationAtom);

	return (
		<DetailsGridLayout>
			<Card sx={{ height: 1 }}>
				<CardHeader title={t('ui.category.users')} />
				<CardContent sx={{ height: rowHeight }}>
					<UserCountPieChart organisationId={organisation?.id} />
				</CardContent>
			</Card>
			<Card sx={{ height: 1 }}>
				<CardHeader title={t('ui.category.items')} />
				<CardContent sx={{ height: rowHeight }}>
					<ItemCountBarChart organisationId={organisation?.id} />
				</CardContent>
			</Card>
			<Card sx={{ height: 1 }}>
				<CardHeader title={t('nav.category.devices')} />
				<CardContent sx={{ height: rowHeight }}>
					<DeviceCountPieChart organisationId={organisation?.id} />
				</CardContent>
			</Card>
		</DetailsGridLayout>
	);
};

export default OverviewStatisticsLayout;
