import { styled } from '@mui/material';
import { useDrawingArea } from '@mui/x-charts';

const StyledText = styled('text')(({ theme }) => ({
	fill: theme.palette.text.primary,
	textAnchor: 'middle',
	dominantBaseline: 'central',
	fontFamily: theme.typography.fontFamily
}));

interface PieChartCenterLabelProps {
	primary?: string;
	secondary?: string | number;
}

/**
 * An component to show labels in a the center of a pie
 * chart
 */
const PieChartCenterLabel = ({
	primary,
	secondary
}: PieChartCenterLabelProps) => {
	const { width, height, left, top } = useDrawingArea();

	return (
		<g>
			<StyledText
				x={left + width / 2} y={top + height / 2 - 22}
				fontSize={18}
			>
				{primary}
			</StyledText>
			<StyledText
				x={left + width / 2}
				y={top + height / 2 + 18}
				fontSize={30}
			>
				{secondary}
			</StyledText>
		</g>
	);
};

export default PieChartCenterLabel;
