import { useState } from 'react';

import { Box, Card, CardContent, CardHeader, Grid2, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { organisationAtom } from '~atoms';
import { PeriodicityEnum } from '~enums';
import {
	RevenuesLineChart,
	BillingPeriodCountBarChart,
	ItemRevenueStatisticsDataGrid,
} from '~features/finances';
import { DateRange } from '~interfaces/dateRanges';

import StatisticsToolbar from '../components/toolbars/statisticsToolbar';

const FinanceStatisticsLayout = () => {
	const { t } = useTranslation('general');
	const organisation = useAtomValue(organisationAtom);
	const [date, setDate] = useState(dayjs());
	const [periodicity, setPeriodicity] = useState(PeriodicityEnum.Monthly);
	const [filters, setFilters] = useState({});

	const getPeriod = (date: Dayjs, periodicity: PeriodicityEnum, monthRange = 1): DateRange => {
		const end = date >= dayjs().startOf('month') ? dayjs() : date.endOf('month');

		return {
			start: end
				.subtract(periodicity === PeriodicityEnum.Monthly ? monthRange : 11, 'months')
				.toDate(),
			end: end.toDate(),
		};
	};

	return (
		<Stack spacing={2} sx={{ height: 1 }}>
			<StatisticsToolbar
				organisationId={organisation?.id}
				date={date}
				onDateChange={(value) => setDate(value)}
				periodicity={periodicity}
				onPeriodicityChange={(value) => setPeriodicity(value)}
				filters={filters}
				onFiltersChange={(value) => setFilters(value)}
			/>
			<Grid2 container spacing={2}>
				<Grid2 size={8}>
					<Card sx={{ flexGrow: 1 }}>
						<CardHeader title={t('nav.financialReports.revenue')} />
						<CardContent sx={{ height: 300 }}>
							<RevenuesLineChart
								organisationId={organisation?.id}
								period={getPeriod(date, periodicity, 3)}
								itemId={filters.item?.id}
								hubId={filters.hub?.id}
							/>
						</CardContent>
					</Card>
				</Grid2>
				<Grid2 size={4}>
					<Card sx={{ flexGrow: 1 }}>
						<CardHeader title={t('payments')} />
						<CardContent sx={{ height: 300 }}>
							<BillingPeriodCountBarChart
								organisationId={organisation?.id}
								period={getPeriod(date, periodicity, 1)}
							/>
						</CardContent>
					</Card>
				</Grid2>
				<Grid2 size={12}>
					<ItemRevenueStatisticsDataGrid
						organisationId={organisation?.id}
						period={getPeriod(date, periodicity, 1)}
						itemId={filters.item?.id}
						hubId={filters.hub?.id}
					/>
				</Grid2>
			</Grid2>
		</Stack>
	);
};

export default FinanceStatisticsLayout;
