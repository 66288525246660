import { useMemo, useRef } from 'react';

import { BarChart, LineChart, PieChart } from '@mui/x-charts';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';

import { PeriodicityEnum } from '~enums';
import { DateRange } from '~interfaces/dateRanges';
import { colorsSlim } from '~themes/chartColors';

import BookingStatisticsService from '../../services/bookingStatisticsService';

const dateLongMonthAndYearFormatter = new Intl.DateTimeFormat('nl', {
	month: 'long',
	year: 'numeric',
});
const dateShortMonthAndYearFormatter = new Intl.DateTimeFormat('nl', {
	month: 'short',
	year: 'numeric',
});

const service = new BookingStatisticsService();

interface BookingsLineChartProps {
	organisationId?: string;
	period: DateRange;
	itemId?: string;
	hubId?: string;
	categoryId?: string;
	periodicity?: PeriodicityEnum;
}

dayjs.extend(weekOfYear);

const now = dayjs();

const BookingsLineChart = ({
	period = {
		start: now.subtract(3, 'month').toDate(),
		end: now.toDate(),
	},
	...props
}: BookingsLineChartProps) => {
	const { t } = useTranslation('general');

	const fetchParameters = useMemo(
		() => ({
			period: period,
			...props
		}),
		[props, period],
	);

	const { data, isLoading } = useSWR([service.basePath, fetchParameters], ([_, args]) =>
		service.getCounts(args),
	);

	return (
		<LineChart
			loading={isLoading}
			colors={colorsSlim}
			xAxis={
				data != null ?
					[
						{
							min: period.start,
							max: period.end,
							scaleType: 'time',
							data: data.results.map((el) => el.timestamp) ?? [],
							valueFormatter: (value: Date, context) => {
								if (context.location !== 'tick' && props.periodicity === PeriodicityEnum.Monthly) {
									return `${t('week')} ${dayjs(value).week().toString()}`;
								} else if (context.location !== 'tick') {
									return dateLongMonthAndYearFormatter.format(value);
								}

								return dateShortMonthAndYearFormatter.format(value);
							},
							// tickInterval: (value: Date) => value.getDate() === 2,
							// tickLabelInterval: (value: Date) => value.getDate() >= 0 && value.getDate() <= 7,
							tickNumber: 3,
						},
					]
				:	undefined
			}
			series={
				data != null ?
					[
						{
							data: data.results.map((el) =>
								el.data.reduce((total, el) => (total += el.bookingCount), 0),
							),
							label: t('totalBookings'),
							showMark: false,
						},
						{
							data: data.results.map((el) =>
								el.data.reduce((total, el) => (total += el.uniqueUsers), 0),
							),
							label: t('uniqueUsers'),
							showMark: false,
						},
					]
				:	[]
			}
			grid={{ horizontal: true }}
		/>
	);
};

export default BookingsLineChart;
