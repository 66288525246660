import { MenuItem, SelectProps } from '@mui/material';
import useSWRImmutable from 'swr/immutable';

import { AsyncSelect, Select } from '~components';
import { ItemCategoryEnum } from '~enums';
import i18n from '~lib/i18n';

import ItemCategory from '../../interfaces/itemCategory';
import ItemCategoryService from '../../services/itemCategoryService';

// const ItemCategorySelect = ({
// 	label = i18n.t('category'),
// 	value,
// 	...props
// }: SelectProps) => {

// 	return (
// 		<Select {...props} label={label} value={value ?? ''}>
// 			<MenuItem value=''>
// 				None
// 			</MenuItem>
// 			{Object.values(ItemCategoryEnum).map((el: ItemCategoryEnum) =>
// 				<MenuItem
// 					key={el}
// 					value={el}
// 				>
// 					{el}
// 				</MenuItem>
// 			)}
// 		</Select>
// 	);
// };

type ItemCategorySelectProps = {
	value: ItemCategory
} & SelectProps;

const service = new ItemCategoryService();

const ItemCategorySelect = ({
	label = i18n.t('ui.label.category'),
	value = null,
	...props
}: ItemCategorySelectProps) => {
	const { data, isLoading } = useSWRImmutable(
		service.basePath,
		() => service.getCategories()
	);

	return (
		<AsyncSelect
			{...props}
			clearable
			label={label}
			loading={isLoading}
			value={value}
			options={data ?? []}
		/>
	);
};

export default ItemCategorySelect;
