import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import path from 'path';

import {
	DeleteOutlined as DeleteOutlinedIcon,
	Edit as EditIcon,
	ImportExport as ImportExportIcon,
} from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Box,
	Button,
	MenuItem,
	Link as MuiLink,
	Portal,
	Stack,
	useTheme,
} from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
	GridSortModel,
} from '@mui/x-data-grid';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { userInfoAtom } from '~atoms';
import { DataGrid, DataGridToolbar, EmptyTable, GridActionsCellItem, Select } from '~components';
import { pagePathSegments } from '~constants';
import { ItemCategoryEnum } from '~enums';
import { useAuthorize } from '~features/authentication';
import { Item } from '~features/items';
import { OrganisationAutocomplete, PartnerAutocomplete } from '~features/organisations';
import { BaseReference } from '~interfaces';
import { ListMutationRefProps } from '~interfaces/refProps';
import { datetimeWithoutSecondsFormat, time24FormatWithoutSeconds } from '~utils/dateUtils';

import DefectSeverityEnum from '../../enum/defectSeverityEnum';
import ServiceTicketStatusEnum from '../../enum/serviceTicketStatusEnum';
import ServiceTicket from '../../interfaces/serviceTicket';
import ServiceTicketsService from '../../services/serviceTicketsService';
import DefectSeverityChip from '../chips/defectSeverityChip';
import ServiceTicketStatusChip from '../chips/serviceTicketStatusChip';

const toolbarIdString = 'topology-participant-toolbar';

const service = new ServiceTicketsService();

interface ServiceTicketsDataGridProps {
	/**
	 * Set a mode on how to view this datagrid. The dashboard mode will leave out some
	 * columns for example
	 */
	mode?: 'dashboard' | 'normal';
	disableToolbar?: boolean;
	ticketStatus?: 'none' | 'open' | 'pending' | 'planned' | 'ready' | 'closed' | 'unclosed';
	organisationId?: string;
	onStatusChange?: (value: BaseReference & { status: ServiceTicketStatusEnum }) => void;
	access?: ('owned' | 'shared')[];
}

const ServiceTicketsDataGrid = forwardRef<ListMutationRefProps, ServiceTicketsDataGridProps>(
	(
		{
			ticketStatus,
			onStatusChange,
			disableToolbar = false,
			mode = 'normal',
			organisationId,
			access = ['owned', 'shared'],
		},
		ref,
	) => {
		const { t } = useTranslation('general');
		const theme = useTheme();
		const location = useLocation();
		const userInfo = useAtomValue(userInfoAtom);

		const { isSuperAdmin } = useAuthorize();

		const [fetchParameters, setFetchParameters] = useState<object | null>({
			access: access,
			organisationId: organisationId,
			status: ticketStatus ? [ticketStatus] : undefined,
		});

		useEffect(() => {
			setFetchParameters((prev) => ({
				...prev,
				status: [ticketStatus],
				organisationId: organisationId,
			}));
		}, [ticketStatus, organisationId]);

		const columns = useMemo<GridColDef<ServiceTicket>[]>(
			() => [
				{
					field: 'id',
					headerName: t('ui.label.id'),
					width: 80,
					hideable: false,
					renderCell: (params) => (
						<MuiLink
							component={ReactRouterLink}
							to={`/${path.join(pagePathSegments.TicketDetails, params.value.toString())}`}
							state={{
								from: location.pathname,
								ticketStatus: ticketStatus,
							}}
						>
							{params.value}
						</MuiLink>
					),
				},
				{
					field: 'dateCreated',
					headerName: t('ui.dateCreated'),
					valueFormatter: (value: Date) => datetimeWithoutSecondsFormat.format(value),
					flex: 1,
					sortable: false,
				},
				{
					field: 'itemGroup',
					headerName: t('ui.label.itemGroup'),
					valueGetter: (_, row) => row.item.itemGroup,
					valueFormatter: (value: BaseReference) => value?.label,
					flex: 1,
				},
				{
					field: 'item',
					headerName: t('ui.label.item'),
					valueFormatter: (value: BaseReference) => value?.label,
					flex: 1,
					sortable: false,
				},
				{
					field: 'category',
					headerName: t('ui.label.category'),
					valueGetter: (_, row) => row.item.category,
					flex: 1,
					sortable: false,
				},
				{
					field: 'location',
					headerName: t('ui.label.location'),
					valueGetter: (_: Item, row) => row.item.hub,
					valueFormatter: (value: BaseReference) => value?.label,
					flex: 1,
					sortable: false,
				},
				{
					field: 'provider',
					headerName: t('ui.provider'),
					valueFormatter: (value: BaseReference) => value?.label,
					flex: 1,
					sortable: false,
				},
				{
					field: 'serviceProvider',
					headerName: t('ui.serviceCompany.label'),
					valueFormatter: (value?: BaseReference) => value?.label ?? '-',
					flex: 1,
					sortable: false,
				},
				{
					field: 'defect',
					headerName: t('ui.label.defect'),
					valueFormatter: (value) => value.label,
					flex: 1,
				},
				{
					field: 'severity',
					headerName: t('ui.label.ticketType'),
					valueGetter: (_, row) => row.defect.severity,
					renderCell: (params) => <DefectSeverityChip status={params.value} />,
					width: 120,
				},
				{
					field: 'status',
					headerName: t('ui.label.status'),
					renderCell: (params) => <ServiceTicketStatusChip status={params.value} />,
					width: 100,
				},
				{
					field: 'actions',
					type: 'actions',
					display: 'text',
					width: 50,
					resizable: false,
					align: 'right',
					hideable: false,
					getActions: (params) => [
						<GridActionsCellItem
							key={`${params.id}-changestatus`}
							icon={<ImportExportIcon />}
							disabled={!isSuperAdmin() && params.row.provider.id != userInfo.organisation.id}
							label={t('view.ticketmanagement.button.inline.changestatus')}
							onClick={() =>
								onStatusChange?.({
									id: params.id.toString(),
									status: params.row.status,
									label: params.row.label,
								})
							}
							showInMenu
						/>,
					],
				},
			],
			[],
		);

		const { data, isLoading, isValidating, error, mutate } = useSWR(
			fetchParameters ? [service.basePath, fetchParameters] : null,
			([_, args]) => service.getServiceTickets(args),
			{
				keepPreviousData: true,
			},
		);
		const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

		useEffect(() => {
			if (data?.total != null) {
				setTotalCount(data.total);
			}
		}, [data]);

		useImperativeHandle(
			ref,
			() => ({
				mutate: mutate,
			}),
			[mutate],
		);

		const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
			if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
				service.abortCurrentRequest('pagination change');
			}

			setFetchParameters((prev) => ({
				...prev,
				page: value.page + 1,
				pageSize: value.pageSize,
			}));
		};

		const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
			const searchQuery =
				model.quickFilterValues && model.quickFilterValues.length > 0 ?
					model.quickFilterValues[0]
				:	undefined;
			const organisation =
				model.items.length > 0 && model.items[0].field === 'organisation' ?
					model.items[0].value
				:	undefined;

			setFetchParameters((prev) => ({
				...prev,
				organisationId: organisation?.id,
				searchQuery: searchQuery,
			}));
		};

		const handleSortModelChange = (model: GridSortModel, details: GridCallbackDetails) => {
			if (isLoading || isValidating) {
				service.abortCurrentRequest('Sort model change');
			}

			setFetchParameters((prev) => ({
				...prev,
				sortBy: model ? model[0]?.field : undefined,
				descending: model ? model[0]?.sort === 'desc' : undefined,
			}));
		};

		const handleFiltersChange = (newVal: object) => {
			setFetchParameters((prev) => ({
				...prev,
				...newVal,
			}));
		};

		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', height: 1, minHeight: 170 }}>
				{!disableToolbar && <Box id={toolbarIdString} sx={{ pb: 2 }} />}
				<DataGrid
					// service tickets open
					snapshotKey='sertic'
					columns={columns}
					rows={data?.results ?? []}
					rowCount={totalCount}
					loading={isLoading || isValidating}
					error={error}
					onPaginationModelChange={handlePaginationChange}
					onFilterModelChange={handleFilterModelChange}
					onSortModelChange={handleSortModelChange}
					pageSizeOptions={mode === 'dashboard' ? [] : undefined}
					disableUrlSync={mode === 'dashboard'}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: mode === 'dashboard' ? 5 : undefined,
							},
						},
						columns: {
							columnVisibilityModel:
								mode === 'dashboard' ?
									{
										status: false,
										serviceProvider: false,
										category: false,
										provider: false,
										location: false,
									}
								:	undefined,
						},
					}}
					slots={{
						toolbar: !disableToolbar ? Toolbar : undefined,
						noRowsOverlay: NoServiceTicketsOverlay,
						noResultsOverlay: NoServiceTicketsOverlay,
					}}
					slotProps={{
						toolbar: {
							filters: fetchParameters,
							onFiltersChange: handleFiltersChange,
						},
						noRowsOverlay: {
							status: ticketStatus,
							title: t('noResourceFound', { resource: t('serviceTickets') }),
						},
						noResultsOverlay: {
							status: ticketStatus,
							title: t('noResourceFound', { resource: t('serviceTickets') }),
						},
					}}
					sx={
						mode !== 'dashboard' ?
							{
								flexGrow: 1,
								flexBasis: 200,
								border: 0,
							}
						:	undefined
					}
				/>
			</Box>
		);
	},
);
ServiceTicketsDataGrid.displayName = 'ServiceTicketsDataGrid';

const NoServiceTicketsOverlay = (props) => {
	const { t } = useTranslation('general');
	const theme = useTheme();

	return (
		<Box sx={{ p: 2, width: 'fit-content' }}>
			<EmptyTable label={t(`views.dashboard.tickets.emptyResults.${props.status}`)} />
		</Box>
	);

	// TODO: transition to the following components
	return (
		<Box sx={{ p: 1, width: 'fit-content' }}>
			<Alert severity='info' sx={{ bgcolor: theme.palette.info.light, borderRadius: 20 }}>
				{t(`views.dashboard.tickets.emptyResults.${props.status}`)}
			</Alert>
		</Box>
	);
};

const Toolbar = (props) => {
	const { t } = useTranslation('general');

	const handleProviderChange = () => {};

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar
				disabledOrganisationFilter
				filters={
					<>
						<PartnerAutocomplete
							label={t('provider')}
							status='accepted'
							onChange={(_, newValue) =>
								props.onFiltersChange?.({
									...props.filters,
									organisationId: newValue?.id,
									access: newValue != null ? ['shared'] : ['owned', 'shared'],
								})
							}
							size='small'
							sx={{ width: 150 }}
						/>
						<Select
							label='Status'
							onChange={(_, newValue) =>
								props.onFiltersChange?.({
									...props.filters,
									status: newValue,
								})
							}
							size='small'
							sx={{ width: 150 }}
						>
							{Object.values(ServiceTicketStatusEnum).map((el: ServiceTicketStatusEnum) => (
								<MenuItem value={el}>{el}</MenuItem>
							))}
						</Select>
						<Select label='Severity' size='small' sx={{ width: 150 }}>
							{Object.values(DefectSeverityEnum).map((el: DefectSeverityEnum) => (
								<MenuItem value={el}>{el}</MenuItem>
							))}
						</Select>
						<Select
							label='Category'
							// size='small'
							sx={{ width: 150 }}
						>
							{Object.values(ItemCategoryEnum).map((el: ItemCategoryEnum) => (
								<MenuItem value={el}>{el}</MenuItem>
							))}
						</Select>
					</>
				}
			>
				<Button variant='contained'>Add ticket</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default ServiceTicketsDataGrid;
