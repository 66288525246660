import { useState } from 'react';

import { Card, CardContent, CardHeader, Grid2, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';

import { organisationAtom } from '~atoms';
import { PeriodicityEnum } from '~enums';
import { BookingsLineChart, BookingStatisticsDataGrid } from '~features/bookings';
import { TripDistancesBarChart } from '~features/trips';
import { DateRange } from '~interfaces/dateRanges';

import StatisticsToolbar from '../components/toolbars/statisticsToolbar';

const BookingStatisticsLayout = () => {
	const { t } = useTranslation('general');
	const organisation = useAtomValue(organisationAtom);
	const [date, setDate] = useState(dayjs());
	const [periodicity, setPeriodicity] = useState(PeriodicityEnum.Monthly);
	const [filters, setFilters] = useState({});

	const getPeriod = (date: Dayjs, periodicity: PeriodicityEnum, monthRange = 1): DateRange => {
		const end = date >= dayjs().startOf('month') ? dayjs() : date.endOf('month');

		return {
			start: end.subtract(periodicity === PeriodicityEnum.Monthly ? monthRange : 12, 'months').toDate(),
			end: end.toDate()
		};
	};

	return (
		<Stack spacing={2} sx={{ height: 1 }}>
			<StatisticsToolbar
				organisationId={organisation?.id}
				date={date}
				onDateChange={(value) => setDate(value)}
				periodicity={periodicity}
				onPeriodicityChange={(value) => setPeriodicity(value)}
				filters={filters}
				onFiltersChange={(newValue) => setFilters(newValue)}
			/>
			<Grid2 container spacing={2}>
				<Grid2 size={8}>
					<Card sx={{ flexGrow: 1 }}>
						<CardHeader title={t('ui.category.bookings')} />
						<CardContent>
							<div style={{ width: '100%', height: 400 }}>
								<BookingsLineChart
									organisationId={organisation?.id}
									periodicity={periodicity}
									period={getPeriod(date, periodicity, 3)}
									itemId={filters.item?.id}
									hubId={filters.hub?.id}
									categoryId={filters.category?.id}
								/>
							</div>
						</CardContent>
					</Card>
				</Grid2>
				<Grid2 size={4}>
					<Card sx={{ flexGrow: 1 }}>
						<CardHeader title={t('ui.label.carDistance')} />
						<CardContent>
							<div style={{ width: '100%', height: 400 }}>
								<TripDistancesBarChart
									organisationId={organisation?.id}
									period={getPeriod(date, periodicity, 1)}
								/>
							</div>
						</CardContent>
					</Card>
				</Grid2>
				<Grid2 size={12} sx={{ width: '100%', minHeight: '300px' }}>
					<BookingStatisticsDataGrid
						organisationId={organisation?.id}
						period={getPeriod(date, periodicity, 1)}
						itemId={filters.item?.id}
						hubId={filters.hub?.id}
						categoryId={filters.category?.id}
					/>
				</Grid2>
			</Grid2>
		</Stack>
	);
};

export default BookingStatisticsLayout;
